.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 10px;
  padding: 40px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.main {
    display:grid;
    grid-template-columns: auto auto auto auto;
}
